import { render, staticRenderFns } from "./crowdfunding.vue?vue&type=template&id=20ec9cb4&scoped=true"
import script from "./crowdfunding.vue?vue&type=script&lang=js"
export * from "./crowdfunding.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/EvStake.css?vue&type=style&index=0&id=20ec9cb4&prod&scoped=true&lang=css&external"
import style1 from "./crowdfunding.vue?vue&type=style&index=1&id=20ec9cb4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ec9cb4",
  null
  
)

export default component.exports