<template>
  <div class="ContractPage">
    <div class="tempContent">
      <div class="stakewait">

        <div class="coinInfomation">          
          <div class="coinImg"><img :src="EvCon" class="dailogo" /></div>
          <div class="coinInfo">{{ $t("Contract.contractaddress") }}</div>
        </div>

        <Divider style="background: rgba(142, 200, 243, 0.1)"/>

        <div class="inputInfoOk">
          <input v-model="shortenedAddress" type="text" class="inputStyle"/>
        </div>

        <div class="maticlink">
          <div class="cLogo"><img :src="matic" alt=""/></div>
          <div class="cName">Polygon</div>
        </div>
        <div class="StakeAuthor">
          <button class="StakeBtn2 ripple" v-clipboard:copy="linkAddress2" v-clipboard:success="onCopy"
                  v-clipboard:error="onError">
            {{ $t("Contract.Copylink") }}
          </button>
        </div>
    <div>
      <video class="btnvideo" src="https://dm.bull-developer.com/video/162.mp4" autoplay loop muted playsinline>
      {{$t("network.video")}}
      </video>
    </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.btnvideo{
  width:100%;
}
</style>
<script>
import EvWatch from "@/assets/img/EvWatch.svg";
import matic from "@/assets/img/matic.svg";
import EvCon from "@/assets/img/EvCon.svg";
import VueClipboard from "vue-clipboard2";
import stylea from "@/assets/img/stylea.png";
import siteapi from "@/json/siteapi.json";
import Vue from "vue";

Vue.use(VueClipboard);
export default {
  name: "EvContract",
  data() {
    return {
      matic,
      EvWatch,
      EvCon,
      stylea,
      invAddress: siteapi.aave_token.address,
      copySuccess: this.$t("Contract.Copylink"),
      copySuccessText: this.$t("Contract.CopylinkText"),
    };
  },
  methods: {
    onCopy() {
      window.open(
        `https://polygonscan.com/address/${this.invAddress}`,
        "_blank"
      );

      // this.success(false);
      // console.log('Text successfully copied');
    },
    onError() {
      console.error("Failed to copy text");
    },
    success(nodesc) {
      this.$Notice.success({
        title: this.copySuccess,
        desc: nodesc ? "" : this.copySuccessText,
      });
    },
  },
  computed: {
    shortenedAddress: {
      get() {
        if (this.invAddress === "") {
          return "0x";
        } else {
          return (
            this.invAddress.slice(0, 8) + " ••• " + this.invAddress.slice(-10)
          );
        }
      },
      set(newVal) {
        this.invAddress = newVal;
      },
    },
    linkAddress2: {
      get() {
        return `https://polygonscan.com/address/${this.invAddress}`;
      },
    },
  },
};
</script>
<style scoped src="@/assets/css/EvContract.css"></style>
<style lang="less" scoped>

</style>
