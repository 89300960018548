import { render, staticRenderFns } from "./FalconIdo2.vue?vue&type=template&id=6e19c962&scoped=true"
import script from "./FalconIdo2.vue?vue&type=script&lang=js"
export * from "./FalconIdo2.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/FalconIdo2.css?vue&type=style&index=0&id=6e19c962&prod&scoped=true&lang=css&external"
import style2 from "./FalconIdo2.vue?vue&type=style&index=2&id=6e19c962&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e19c962",
  null
  
)

export default component.exports