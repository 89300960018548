<template>
  <div>
    <!-- v-show="isBind" -->
    <div class="stakewait">


      <div class="coinInfomation" style="background-color: rgba(142, 200, 243, 0.0);">
            <div class="coinImg"><img :src="share" class="dailogo" /></div>
            <div class="coinInfo" style="color: #fff;">
              {{ $t("Share.sharetitle") }}
            </div>  
      </div>  

      <div class="coinInfomation">
        <div class="coinInfo2">
          <!-- {{ usersdata.teamInvestAmount }} -->
          <counts
            :start="0"
            :end="parseFloat(usersdata.teamInvestAmount.replace(/,/g, ''))"
            :duration="1000"
          />
        </div>
      </div>

       <Divider style="background: rgba(142, 200, 243, 0.2)"/>

      <div class="inputInfoOk" v-show="isBind">
        <!-- <input v-model.number="daiValue" type="text" class="inputStyle"> -->
        {{ $t("Team.innver") }}: {{ shortinnverAddress }}
      </div>
      <div class="inputInfo" v-show="isBind == false">
        <input v-model="quer" type="text" class="inputStyle"/>
      </div>
      <div class="StakeInfo">
        <div class="StakeResult">
          <img :src="share1" alt=""/>{{$t("Share.Honor")}} : {{ honorLeave }}
        </div>
        <div class="StakeMoney">
          <img :src="share2" alt=""/> {{$t("Share.Team")}} : {{ usersdata.teamCount }}
        </div>
      </div>
      <div class="StakeInfo">
        <div class="StakeResult">
          <img :src="share3" alt=""/> {{$t("Share.big")}} : {{ Math.floor(regionalCommunitydata.largestChildPerformance)  }}
        </div>
        <div class="StakeMoney">
          <img :src="share4" alt=""/> {{$t("Share.small")}} : {{ Math.floor(regionalCommunitydata.minPerformance)  }}
        </div>
      </div>

      <div class="StakeAuthor22" v-show="isBind == false">
        <button class="StakeBtn22 ripple" @click="bindrel()" v-if="typebind == 0">
          {{ $t("Team.Bind") }}
        </button>
        <button class="StakeBtn22 ripple" v-else>
          {{ $t("Team.Binding") }}
        </button>

        <button class="StakeBtn22 ripple" style="width: 48%;" v-clipboard:copy="linkAddress2" v-clipboard:success="onCopy"
                v-clipboard:error="onError">
          {{ $t("Share.copyCommnad") }}
        </button>
        
      </div>
      



      <div class="StakeAuthor22" v-show="isBind == true">
       

        <button class="StakeBtn22 ripple" style="width: 100%;" v-clipboard:copy="linkAddress2" v-clipboard:success="onCopy"
                v-clipboard:error="onError">
          {{ $t("Share.copyCommnad") }}
        </button>
      </div>

      <!-- <div class="StakeAuthor">
            <button class="StakeBtn2">{{ $t('Team.Bind') }}</button>
        </div> -->
        <div>
      <video class="btnvideo" src="https://dm.bull-developer.com/video/202504.mp4" autoplay loop muted playsinline>
      {{$t("network.video")}}
      </video>
    </div>
      <!--loading start-->
      <div v-if="isloading" class="loading">
        <img :src="loadingimg" class="loadimg"/>
      </div>
      <!--loading end-->
    </div>
    <canvas id="confetti-canvas" ref="confettiCanvas"></canvas>
  </div>
</template>
<style scoped src="@/assets/css/EvTeam.css"></style>
<style lang="less" scoped>
.btnvideo{
  width: 100%;
  
}
#confetti-canvas {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
pointer-events: none; /* 防止干扰用户操作 */
z-index: 9999; /* 保证显示在最上层 */
}
.StakeAuthor22{
  display: flex;
  margin-top: 30px;
  gap: 5px;
  .StakeBtn22{
    flex: 1;
    height: 45px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #020000;
    font-size: 16px;
    background: url("../assets/img/bgs.svg") no-repeat center center/cover;
  }
}

</style>
<script>
import confetti from "canvas-confetti"; 
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import link from "@/assets/img/link.svg";
import honor from "@/assets/img/honor.svg";
import counts from "@/components/VueCount.vue";
import contract from "@/plugins/contract.js";
import loadingimg from "@/assets/img/loading.svg";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import sign from "@/plugins/sign.js";
import select from "@/plugins/select";
import big from "big.js";
import share1 from "@/assets/img/share1.svg";
import share2 from "@/assets/img/share2.svg";
import share3 from "@/assets/img/s3.svg";
import share4 from "@/assets/img/share4.svg";
import share from "@/assets/img/share.svg";
export default {
  data() {
    return {
      videoList: [
        "https://dm.bull-developer.com/video/162.mp4",
        "https://dm.bull-developer.com/video/163.mp4",
      ],
      currentIndex: 0, // 当前播放视频索引
      typeApprove: 0,
      empowerbutton: false,
      isBind: false,
      link: link,
      honor: honor,
      dai: dai,
      share:share,
      share1:share1,
      share2:share2,
      share3:share3,
      share4:share4,
      daimni: daimni,
      daiValue: 0,
      loadingimg: loadingimg,
      daiOwnMoney: 0.0,
      stylea: stylea,
      quer: this.$route.query.ref,
      honorLeave: '',
      linkMember: 0,
      innverAddress: "",
      typebind: 0,
      isloading: false,
      usersdata: {
        teamCount: "",
        teamInvestAmount: '0.00',
      },
      getUserLookInfodata: {},
      regionalCommunitydata:{},
      appurl: process.env.VUE_APP_URL,
      invAddress: "",
      copySuccessText: this.$t("Share.copyText"),
    };
  },
  components: {
    counts,
  },
  computed: {

    shortinnverAddress: {
      get() {
        if (this.innverAddress === "") {
          return "0x";
        } else {
          return (
            this.innverAddress.slice(0, 4) + "•••" + this.innverAddress.slice(-8)
          );
        }
      },
      set(newVal) {
        this.innverAddress = newVal;
      },
    },
    shortQuerAddress: {
      get() {
        if (this.quer === "") {
          return "";
        } else {
          return this.quer.slice(0, 10) + "•••" + this.quer.slice(-9);
        }
      },
      set(newVal) {
        this.innverAddress = newVal;
      },
    },
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.00";
      let value = parseFloat(this.daiOwnMoney.toFixed(2));
      return value
    },
    shortenedAddress: {
      get() {
        if (this.invAddress === "") {
          return "0x";
        } else {
          return this.invAddress.slice(0, 8) + " ••• " + this.invAddress.slice(-9);
        }
      },
      set(newVal) {
        this.invAddress = newVal;
      },
    },
    linkAddress2: {
      get() {
        return `${process.env.VUE_APP_URL}?ref=${this.invAddress}`;
      },
    },
  },
  async mounted() {
    this.invAddress = await web3Utils.connectWallet();
    // is network ok
    if (typeof window.ethereum !== 'undefined') {
      // console.log("contencted wallet");

      // anyc get information
      window.ethereum
        .request({method: 'eth_chainId'})
        .then((chainId) => {
          // is polygon chain
          if (chainId === '0x89') {
            // console.log("current network is Polygon");
            // this.$router.push({path:'/EvStake'});
          } else {
            // console.log("current is not polygon chain");
            this.$router.push({path: '/'});
            return
          }
        })
        .catch((error) => {
          //console.error('get network info error:', error);
        });
    } else {
      //console.log("No Connection");
    }
    this.sitedata = siteapi
    const currentAddress = await web3Utils.connectWallet();
    // console.log(currentAddress)
    this.addresscode = currentAddress
    //   var observeAddress= window.localStorage.getItem('observeAddress')
    //  if(observeAddress){
    //   this.addresscode=observeAddress
    //  }else{
    //   this.addresscode=currentAddress
    //  }
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(currentAddress)
      // );

      // console.log(this.daiOwnMoney.toFixed(6));
      this.signtype()

      // this.getMyUserInterest()
      // this.exchangeallowance()
    }
  },
  methods: {
    playNextVideo() {
      // 切换到下一个视频
      this.currentIndex = (this.currentIndex + 1) % this.videoList.length;
    },
    launchConfetti() {
       const duration = 2 * 1000; // 纸屑效果持续时间
       const animationEnd = Date.now() + duration;
       const colors = ["#ff5733", "#33ff57", "#5733ff", "#ff33aa", "#33aaff"];

       const frame = () => {
        const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return;
      }

      confetti({
        particleCount: 5, // 每次产生纸屑数
        startVelocity: 30, // 初始速度
        spread: 360, // 全角度扩散
        origin: {
          x: Math.random(), // 随机水平位置
          y: Math.random() - 0.2 // 随机垂直位置
        },
        colors
      });

      requestAnimationFrame(frame); // 循环触发
    };

    frame(); // 启动纸屑动画
  },
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {
          console.log(res, '签名验证')
          if (res.code == 0) {
            this.getUserLookInfo()
            this.users()
            this.exchangeallowance();
            this.regionalCommunity()
          }
          this.isloading = false;
        });
    },
    onCopy() {
      this.success(false);
      // console.log('Text successfully copied');
    },
    success(nodesc) {
      this.$Notice.success({
        title: nodesc ? "" : this.copySuccessText,
        //desc: nodesc ? "" : this.copySuccessText,
      });
    },
    onError() {
      console.error("Failed to copy text");
    },
    // Authorization button
    empower() {
      this.isloading = true;  // open loading
      this.Approve();
    },
    // contract Approve
    async Approve() {
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.USDT_swap.address;
      spender = this.sitedata.aave_token.address;
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.empowerbutton = true;
            this.validateAmount();
          } else {
            // this.typeApprove = 1;
            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    // View the number of authorized contracts
    async exchangeallowance() {
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.USDT_swap.address;
      spender = this.sitedata.aave_token.address;
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        if (result.data * 1 >= 10000) {
          this.empowerbutton = true;
        } else {
          this.empowerbutton = false;
        }

      });
    },
    async regionalCommunity() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      // console.log('User Information')
      ContractAddress = this.sitedata.select_token.address;
      await select.regionalCommunity(
        this.addresscode,  //current address
        ContractAddress,  //call contract address
        _decimals,
        this.sitedata.aave_token.address
      ).then((res) => {
        console.log(res, 'regionalCommunity');
        this.regionalCommunitydata=res.data
      });
    },
    // User information
    async users() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      // console.log('User Information')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.users(
        this.addresscode,  //current address
        ContractAddress,  //call contract address
        _decimals
      ).then((res) => {
        //console.log(res, 'User Information');
        if (res.data.referrer == '0x0000000000000000000000000000000000000000') {
          this.isBind = false
        } else {
          this.isBind = true
        }
        this.innverAddress = res.data.referrer
        // if (res.data.level == 0) {
        //   this.honorLeave = this.$t("leavel.0")
        // } else {
        //   this.honorLeave = this.$t("leavel." + res.data.level)
        // }

        // this.usersdata = res.data
        // this.usersdata.teamInvestAmount = (this.usersdata.teamInvestAmount * 1).toFixed(2)
        // this.daiOwnMoney=res.data.investAmount*1
        // this.daiTemeMoney=res.data.rewardAmount*1
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    async getUserLookInfo() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      // console.log('User Information')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.getUserLookInfo(
        this.addresscode,  //current address
        ContractAddress,  //call contract address
        _decimals
      ).then((res) => {
        console.log(res, 'getUserLookInfo');
        this.getUserLookInfodata = res.data
        this.usersdata.teamCount = res.data[0]        
        this.usersdata.teamInvestAmount = big(res.data[1]).div(10 ** 18).toFixed(2)
          
        if (res.data[2] == 0) {
          this.honorLeave = this.$t("leavel.0")
        } else {
          this.honorLeave = this.$t("leavel." + res.data[2])
        }
      });
    },
    warning(nodesc) {
      this.$Notice.warning({
        title: nodesc ? "" : this.$t("Team.info"),
      });
    },
    bindrel() {
      //console.log(this.quer)
      if (this.quer == undefined || this.quer == '') {
        this.$Notice.warning({
          title: this.$t("Team.Bindinfo"),
        });
      } else {
        this.isloading = true;
        this.bind()
      }
      // this.warning(false);
    },
    // Bind
    async bind() {
      //console.log("Binding")
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typebind = 1;
      try {
        await contract.bind(
          Address, //contact address
          this.quer, //Bind address
          this.addresscode,//current user address
          _decimals,//float number

        ).then((res) => {
          //console.log(res);
          this.typebind = 0;
          if (res.code == 0) {
            this.typebind = 0;
            this.isloading = false;
            this.launchConfetti();
            this.$Notice.success({
              title: this.$t("Team.BindingSuccessful"),
            });
            this.users()
            this.getUserLookInfo()
            // this.getMyUserInterest()
          } else {
            // this.typeApprove = 1;
            this.isloading = false;
            this.$Notice.warning({
              title: this.$t("Team.BindingFailed"),
            });
          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Team.BindingFailed"),
        });
        //console.log(error);
      }
    },
  },

};
</script>
