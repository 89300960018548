<template>
  <div>
    <CountTo :startVal="start" :endVal="end" :duration="duration"/>
  </div>
</template>

<script>
import CountTo from 'vue-count-to';

export default {
  components: {
    CountTo
  },
  props: {
    start: {
      type: Number,
      default: 0
    },
    end: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      default: 1000
    }
  }
};
</script>
