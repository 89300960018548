import Web3 from "web3";
import big from "big.js";
import {ethers} from "ethers";
import i18n from "../utils/i18n";
// tb合约pg合约方法
export default {
  // 用户信息
  async users(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/flash.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.users(currentAddress)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }
      newData.teamAmount = big(newData.teamAmount)
        .div(10 ** 18)
        .toFixed();
        newData.amount = big(newData.amount)
        .div(10 ** 18)
        .toFixed();
        

      return {code: "0", data: newData};
    } catch (error) {
      console.log(error);
    }
  },
  async getCount(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/flash.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getCount(currentAddress)
      console.log(balance)
   var datas
         datas = big(balance.toString())
           .div(10 ** 0)
           .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },
  async getAmounts(currentAddress, ContractAddress, _decimals,value) {
    try {

      let Factory;
      Factory = require("../json/abi/flash.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getAmounts(value)
      console.log(balance)
   var datas
         datas = big(balance)
           .div(10 ** 18)
           .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },
    async deposit(tokenAddress, amountADesired, CurrentAccount, _decimals) {
      try {
  
        let Factory;
        Factory = require("../json/abi/flash.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
  
  
        // const amountApproved = big(amountADesired)
        //   .times(10 ** _decimals)
        //   .toFixed();
        
        const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
  
        const approveFunction = tokebContract.methods.deposit(amountADesired); // 替换为您要授权的代币数量
  
        // gas
        const getGasPrice = await web3.eth.getGasPrice()
        const getGasPriceto = getGasPrice.toString();
        const hexValue = await approveFunction.estimateGas({
          from: CurrentAccount,
          to: tokenAddress,
          data: approveFunction.encodeABI(),
        });
  
  
        await tokebContract.methods
          .deposit(amountADesired)
          .send({
            from: CurrentAccount,
            //to: tokenAddress,
            data: approveFunction.encodeABI(),
            //gasLimit: hexValue,
            gas: hexValue,
            gasPrice: Math.round(getGasPriceto * 1.5),
          });
  
        return {code: "0"};
      } catch (error) {
        console.log(error)
        let errorMessage = "Unknown error";
        let errorData = {};
        let errorCode = "1";
        // 检查 error 对象并提取详细的错误信息
        if (error.message) {
          errorMessage = error.message;
        }
  
        if (errorMessage.includes("too many arguments")) {
          errorCode = "-1";
          errorMessage = "Node error! Please change the node.";
        }
  
        if (error.data) {
          errorData = error.data;
          if (error.data.code) {
            errorCode = error.data.code;
          }
        }
  
        return {
          code: errorCode,
          error: {
            message: errorMessage,
            data: errorData,
          },
        };
  
  
      }
    },
    async withdraw(tokenAddress, amountADesired, CurrentAccount, _decimals) {
        try {
          //   let position = "";
          //     console.log(_decimals)
          let Factory;
          Factory = require("../json/abi/flash.json");
          const ethereum = window.ethereum;
          const web3 = new Web3(Web3.givenProvider || ethereum);
          // 创建合约对象
          //        console.log(Factory.abi)
    
        //   const amountApproved = big(amountADesired)
        //     .times(10 ** _decimals)
        //     .toFixed();
          //   console.log(amountApproved)
          const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
          // console.log(tokenAddress)
          // console.log(tokebContract)
          const approveFunction = tokebContract.methods.withdraw(); // 替换为您要授权的代币数量
          //  console.log(approveFunction)
          // gas
          const getGasPrice = await web3.eth.getGasPrice()
          const getGasPriceto = getGasPrice.toString();
          //  预估
          const hexValue = await approveFunction.estimateGas({
            from: CurrentAccount,
            to: tokenAddress,
            data: approveFunction.encodeABI(),
          });
          //  console.log(hexValue);
    
          await tokebContract.methods
            .withdraw()
            .send({
              from: CurrentAccount,
              //gasLimit: hexValue,
              //to: tokenAddress,
              data: approveFunction.encodeABI(),
              gas: hexValue,
              gasPrice: Math.round(getGasPriceto * 1.5),
            });
    
          return {code: "0"};
        } catch (error) {
          let errorMessage = "Unknown error";
          let errorData = {};
          let errorCode = "1";
          // 检查 error 对象并提取详细的错误信息
          if (error.message) {
            errorMessage = error.message;
          }
    
          if (errorMessage.includes("too many arguments")) {
            errorCode = "-1";
            errorMessage = i18n.t("ido.message");
          }
          if (error.data) {
            errorData = error.data;
            if (error.data.code) {
              errorCode = error.data.code;
            }
          }
    
          return {
            code: errorCode,
            error: {
              message: errorMessage,
              data: errorData,
            },
          };
        }
      },
      async bind(tokenAddress, amountADesired, CurrentAccount, _decimals) {
        try {
          //   let position = "";
          //     console.log(_decimals)
          let Factory;
          Factory = require("../json/abi/flash.json");
          const ethereum = window.ethereum;
          const web3 = new Web3(Web3.givenProvider || ethereum);
          // 创建合约对象
          //        console.log(Factory.abi)
    
        //   const amountApproved = big(amountADesired)
        //     .times(10 ** _decimals)
        //     .toFixed();
          //   console.log(amountApproved)
          const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
          // console.log(tokenAddress)
          // console.log(tokebContract)
          const approveFunction = tokebContract.methods.bind(amountADesired); // 替换为您要授权的代币数量
          //  console.log(approveFunction)
          // gas
          const getGasPrice = await web3.eth.getGasPrice()
          const getGasPriceto = getGasPrice.toString();
          //  预估
          const hexValue = await approveFunction.estimateGas({
            from: CurrentAccount,
            to: tokenAddress,
            data: approveFunction.encodeABI(),
          });
          //  console.log(hexValue);
    
          await tokebContract.methods
            .bind(amountADesired)
            .send({
              from: CurrentAccount,
              //gasLimit: hexValue,
              //to: tokenAddress,
              data: approveFunction.encodeABI(),
              gas: hexValue,
              gasPrice: Math.round(getGasPriceto * 1.5),
            });
    
          return {code: "0"};
        } catch (error) {
          let errorMessage = "Unknown error";
          let errorData = {};
          let errorCode = "1";
          // 检查 error 对象并提取详细的错误信息
          if (error.message) {
            errorMessage = error.message;
          }
    
          if (errorMessage.includes("too many arguments")) {
            errorCode = "-1";
            errorMessage = i18n.t("ido.message");
          }
          if (error.data) {
            errorData = error.data;
            if (error.data.code) {
              errorCode = error.data.code;
            }
          }
    
          return {
            code: errorCode,
            error: {
              message: errorMessage,
              data: errorData,
            },
          };
        }
      },
}