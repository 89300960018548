import { render, staticRenderFns } from "./EvFalocnV4.vue?vue&type=template&id=dc77a4c4&scoped=true"
import script from "./EvFalocnV4.vue?vue&type=script&lang=js"
export * from "./EvFalocnV4.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/EvFalocnV4.css?vue&type=style&index=0&id=dc77a4c4&prod&scoped=true&lang=css&external"
import style1 from "./EvFalocnV4.vue?vue&type=style&index=1&id=dc77a4c4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc77a4c4",
  null
  
)

export default component.exports